import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { faClone, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { SecurityService } from '../services/security/security.service';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-promotion-display-templates-tab',
  templateUrl: './promotion-display-templates-tab.component.html',
  styleUrls: ['./promotion-display-templates-tab.component.scss']
})
export class PromotionDisplayTemplatesTabComponent implements OnInit, OnChanges {
  @Input() public selectedPromotionId: number;
  @Input() selectedPersonId: number;
  @Input() public selectedProgramId: number;
  @Input() selectedPackageId: number;
  promotionDisplayTemplates: any[];
  faPlus = faPlus;
  faPencilAlt = faPencilAlt;
  faClone = faClone;
  faTrash = faTrash;
  searchFilters: SearchFilters;
  statuses: Choice[];
  offset = 0;
  filter = new Filter();
  isLoading = false;
  lengthToCompare = 0;
  disableTemplateEdit: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private promotionService: PromotionService,
    private tableHelperService: TableHelperService,
    private router: Router,
    private securityService: SecurityService,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPersonId?.currentValue || !changes.selectedPackageId?.firstChange) {
      this.initNewSearch();
    }
  }

  ngOnInit(): void {
    this.securityService.disableTemplateEdit.subscribe((data: any) => {
      this.disableTemplateEdit = data;
    });
    this.setStatuses();
    this.searchFilters = {
      formName: 'promotionTemplateTab_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
      ],
      searchCallback: () => { this.initNewSearch(); },
    };
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  deleteTemplate(tempId: number): void {
    this.promotionService.deletePromotionDisplayTemplate(tempId).subscribe(() => {
      this.responseHelperService.success('Promotion Display Template successfully deleted');
      this.initNewSearch();
    });
  }


  initNewSearch(): void {
    this.initFilter();
    this.getPromotionDisplayTemplates();
  }

  initFilter(): void {
    this.filter.offset = this.offset;
    this.filter.dir = 'desc';
    this.filter.includeCount = true;
    if (this.selectedPackageId) {
      this.filter.packageId = this.selectedPackageId;
    } else {
      delete this.filter.packageId;
    }
  }

  getPromotionDisplayTemplates(): void {
    if (this.searchFilters) {
      this.filter = this.searchFilters.getFilters(this.filter);
    }
    this.isLoading = true;
    this.promotionService.getPromotionDisplayTemplates(this.selectedPersonId, this.filter).subscribe((data: any) => {
      this.promotionDisplayTemplates = parseResponse(data);
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  editTemplate(temp: any): void {
    this.router.navigate(this.getUrlEditDisplayTemplate(temp));
  }


  private getUrlEditDisplayTemplate(temp: any): any[] {
    return ['/programs/' + this.selectedProgramId + '/promotions/' + this.selectedPersonId + '/promotion-display-template/' + temp.id];
  }
}
