<div class="panel-body">
    <div class=" flex-pull-right">
        <a class="btn btn-dark-blue btn-sm flex-pull-right mb-2" title="Add Display Template"
            href="/programs/{{selectedProgramId}}/promotions/{{selectedPersonId}}/promotion-display-template/"
            *ngIf="!disableTemplateEdit">
            <fa-icon [icon]="faPlus"></fa-icon>
        </a>
    </div>
    <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
    <div class="row">
        <div class="col-md-12">
            <div>
                <div class="row table-configurations m-b">
                    <table class="table table-pointable table-bordered" width="100%">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Template Name</th>
                                <th>Package Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!isLoading">
                            <tr *ngFor="let temp of promotionDisplayTemplates">
                                <td>{{temp.id}}</td>
                                <td>{{temp.name}}</td>
                                <td>{{temp.packageName}}</td>
                                <td>
                                    <button class="btn btn-dark-blue btn-sm edit" (click)="editTemplate(temp)"
                                        *ngIf="!disableTemplateEdit"
                                        title="Edit Display Template">
                                        <fa-icon [icon]="faPencilAlt"></fa-icon>
                                    </button>
                                    <a class="btn btn-dark-blue btn-sm edit"
                                        href="/programs/{{selectedProgramId}}/promotions/{{selectedPersonId}}/promotion-display-template/{{temp.id}}?clone=true"
                                        title="Clone Display Template" *ngIf="!disableTemplateEdit">
                                        <fa-icon [icon]="faClone"></fa-icon>
                                    </a>
                                    <button class="btn btn-attention btn-sm delete action"
                                        (click)="deleteTemplate(temp.id)" [disabled]="temp.sponsorCount>0"
                                        *ngIf="!disableTemplateEdit" title="Delete">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr app-is-there-more  waitUntilApply = 'true' [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
                        </tbody>
                    </table>
                    <div *ngIf="isLoading" class="align-text-center">
                        <div [hidden]="(!isLoading)" class="loading-overlay">
                            <i class="fa fa-spinner fa-5x fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
